var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseCard",
    { staticClass: "libraryList" },
    [
      _c("FileUploader", {
        on: {
          successfulUpload: function ($event) {
            return _vm.get()
          },
        },
      }),
      _c("div", { staticClass: "imagesContainer" }, [
        _c(
          "div",
          {
            staticClass: "imagesList",
            style: `grid-template-columns: repeat(auto-fill, minmax(${_vm.gridItemSize}px, 1fr))`,
          },
          _vm._l(_vm.selectedItems, function (item, i) {
            return _c(
              "div",
              { key: `item${item.id + "selected"}`, staticClass: "imagesItem" },
              [
                _c(
                  "div",
                  {
                    staticClass: "imageContainer",
                    style: `height: ${_vm.gridItemSize}px`,
                    on: {
                      click: function ($event) {
                        return _vm.deselectPhoto(item, i)
                      },
                    },
                  },
                  [
                    _c("BaseImage", {
                      staticClass: "image",
                      attrs: {
                        source: item,
                        size: "thumb",
                        fill: item.type === "pdf" ? false : true,
                        fit: item.type === "pdf" ? true : false,
                        "is-pdf": item.type === "pdf",
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          {
            staticClass: "row justify-content-between align-items-end filters",
          },
          [
            !_vm.fixedType
              ? _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("filterBy", { item: _vm.$tc("type") }))
                      ),
                    ]),
                    _c(
                      "BaseDropdown",
                      {
                        attrs: {
                          "title-classes": "btn btn-secondary",
                          title: _vm.activeTypeTitle,
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-item",
                            on: {
                              click: function ($event) {
                                return _vm.filterByType("")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("allMedia")))]
                        ),
                        _vm._l(_vm.types, function (mediaType) {
                          return _c(
                            "a",
                            {
                              key: mediaType + "type",
                              staticClass: "dropdown-item",
                              on: {
                                click: function ($event) {
                                  return _vm.filterByType(mediaType)
                                },
                              },
                            },
                            [_vm._v(_vm._s(mediaType))]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("BaseInput", {
                  staticClass: "search",
                  attrs: { placeholder: _vm.$t("search") },
                  on: {
                    keyup: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.lazyType(_vm.filterBySearch, _vm.lazyTypeDelay)
                    },
                  },
                  model: {
                    value: _vm.keyword,
                    callback: function ($$v) {
                      _vm.keyword = $$v
                    },
                    expression: "keyword",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "imagesList",
            style: `grid-template-columns: repeat(auto-fill, minmax(${_vm.gridItemSize}px, 1fr))`,
          },
          _vm._l(_vm.items, function (item) {
            return _c(
              "div",
              {
                key: `item${item.id}`,
                staticClass: "imagesItem",
                attrs: { title: item.title },
              },
              [
                _c(
                  "div",
                  {
                    class: ["imageContainer", { selected: item.selected }],
                    style: `height: ${_vm.gridItemSize}px`,
                    on: {
                      click: function ($event) {
                        return _vm.selectPhoto(item)
                      },
                    },
                  },
                  [
                    _c("BaseImage", {
                      staticClass: "image",
                      attrs: {
                        source: item,
                        size: "thumb",
                        fill: item.type === "pdf" ? false : true,
                        fit: item.type === "pdf" ? true : false,
                        "is-pdf": item.type === "pdf",
                      },
                    }),
                    _c("div", { staticClass: "optionsOverlay" }, [
                      item.type !== "pdf"
                        ? _c("div", { staticClass: "resolution" }, [
                            _vm._v(
                              " " +
                                _vm._s(item.width) +
                                " x " +
                                _vm._s(item.height) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      item.type === "pdf"
                        ? _c("div", { staticClass: "title" }, [
                            _vm._v(" " + _vm._s(item.title) + " "),
                          ])
                        : _vm._e(),
                      _vm.selectItems === false
                        ? _c(
                            "div",
                            { staticClass: "optionsTools" },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: `/library/${item.id}` } },
                                [
                                  _c("BaseIcon", {
                                    staticClass: "iconEdit",
                                    attrs: {
                                      "icons-group": "far",
                                      icon: "fa-edit",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("BaseIcon", {
                                staticClass: "iconDelete",
                                attrs: { "icons-group": "fas", icon: "fa-ban" },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmRemove(item.id)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "paginationContainer" },
          [
            _c("Pagination", {
              attrs: {
                offset: _vm.offset,
                "total-items": _vm.totalItems,
                limit: _vm.limit,
                "total-displayed": _vm.items ? _vm.items.length : null,
              },
              on: { updatePagination: _vm.updatePagination },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }